import React, { useEffect } from "react";
import backgroundImage from "../../../Images/ktnLogo/bg-image.svg";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

const HomeBanner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation
      once: true, // Only trigger once
    });
  }, []);

  return (
    <div className="py-10 sm:py-16 md:py-20 flex items-center justify-center p-4 md:p-0 overflow-hidden font-playfair">
      <div
        className="flex items-center justify-center w-full max-w-6xl text-white py-20 px-6 sm:px-10 lg:px-12 rounded-lg bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
        data-aos="fade-up" // AOS fade-up animation
      >
        <div className="text-center w-full max-w-5xl p-4 sm:p-6">
          <h2
            className="text-lg sm:text-3xl md:text-3xl font-semibold mb-4"
            data-aos="fade-down" // AOS fade-down animation
          >
            Are you ready to partner with us in building the future?{" "}
          </h2>
          <h2
            className="text-lg sm:text-2xl md:text-3xl font-semibold mb-6 sm:mb-8"
            data-aos="fade-left" // AOS fade-left animation
          >
            We'd love to learn more about your story.
          </h2>

          <a
            href="https://wjqwrc5sl98.typeform.com/to/PausBFZl"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex"
          >
            <button className=" font-playfair bg-[#1d3072] text-white  text-md sm:text-base md:text-xl 2xl:text-2xl px-5 py-3  rounded-md  hover:brightness-110  transition duration-300 will-change-transform hover:bg-[#949a84] hover:scale-105">
              Engage Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
