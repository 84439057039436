import HTBLO from "../../Images/blogpics/afroprenueship/HTBLO.webp";

const FeaturedPostConts = {
  title: "How the Best Leaders Own Their Digital Space Using Effective Communication",
  date: "March 28,2025",
  description:
    "Leadership today extends beyond boardrooms and stages into the digital world. The best leaders don’t just exist online; they dominate their space, shaping narratives, influencing opinions, and building strong communities...",
  image: HTBLO,
  author: "Afropreneurship",
};

export default FeaturedPostConts;
