import React from "react";
import KickFooter from '../../../Images/ktnLogo/KickFooter.png';
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer
      className="bg-contain md:bg-cover bg-center text-gray-400 py-2 md:py-20 font-playfair"
      style={{ backgroundImage: `url(${KickFooter})` }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="container mx-auto px-4 2xl:px-0">
          {/* Top Section */}
          <div className="flex flex-col md:flex-row justify-between items-start lg:items-center mb-40 md:mb-80 2xl:mb-96"> {/* Changed to flex-col on mobile */}
            {/* Links */}
            <div className="flex space-x-20 lg:space-x-20 text-sm mt-1 md:mt-10 2xl:mt-0 order-2 md:order-1"> {/* Added order for mobile */}
              <a
                href="/"
                className="text-white text-lg md:text-xl 2xl:text-2xl" 
              >
                Services
              </a>
              <a
                href="/ktn"
                className="text-white text-lg md:text-xl 2xl:text-2xl" 
              >
               Ktn
              </a>
            </div>

            {/* Social Icon */}
            <a
              href="https://www.linkedin.com/company/kickandcosynergy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white p-2 border border-gray-400 rounded transition-colors duration-300 mt-8 mb-4 md:mt-10 order-3 md:order-2" // Added order
            >
              <FaLinkedin                 className="text-white" 
 size={20} />
            </a>
          </div>

          {/* Bottom Section */}
          <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center text-center md:text-left text-lg md:text-xl"> {/* Added flex-col and text-center */}
            {/* Copyright */}
            <div className="order-3 md:order-1 mt-2 md:mt-0 mb-5 md:mb-0 text-sm lg:text-xl"> {/* Added order and margin */}
              © {new Date().getFullYear()} KICK & CO. All rights reserved.
            </div>

            {/* Links */}
            <div className="flex space-x-6 lg:space-x-10 mb-2 lg:mb-0 xl:space-x-20 order-1 md:order-2"> {/* Added order and margin */}
              <a
                href="/contact-us"
                className="hover:text-white transition-colors duration-300 text-sm lg:text-lg md:text-xl 2xl:text-2xl"
              >
                Contact Us
              </a>
              <a
                href="/privacy-policy"
                className="hover:text-white transition-colors duration-300 text-sm lg:text-lg md:text-xl 2xl:text-2xl"
              >
                Privacy Policy
              </a>
              <a
                href="/career"
                className="hover:text-white transition-colors duration-300 text-sm lg:text-lg md:text-xl 2xl:text-2xl"
              >
                Careers
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;