import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Tom from "../../../Images/testimonialpics/Tom.jpg";
import Ralph from "../../../Images/testimonialpics/Ralph.webp";
import Susan from "../../../Images/testimonialpics/susan.webp";
import Christina from "../../../Images/testimonialpics/chritiana.webp";
import Travis from "../../../Images/testimonialpics/travis.webp";
import Ag from "../../../Images/testimonialpics/ag.webp";
import Rebecca from "../../../Images/testimonialpics/rebecca.webp";
import Donnie from "../../../Images/testimonialpics/donnie.webp";
import Eze from "../../../Images/testimonialpics/eze.webp";
import Megan from "../../../Images/testimonialpics/megan.webp";
import David from "../../../Images/testimonialpics/david.webp";
import Solaru from "../../../Images/testimonialpics/solaru.webp";
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';



const TestimonialSlider = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  
  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });
  }, []);
  


  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      text: `I've worked closely with Kalu and his team to help my insurtech start-up gain awareness and traction with clients. I'm extremely pleased with the results we have achieved. We've gone from "stealth" status, with virtually NO awareness and NO customers to now where I'm a sought-after keynote speaker at industry events. I lead a YouTube Channel with nearly 30,000 subscribers, I have some videos with over 50,000 views, and for the first time, clients are approaching me! In addition, Kalu is smart, patient, fun to work with, and has proven himself to be a true "thought partner" who takes a multidisciplinary approach to achieve our business goals. I recommend him highly.`,
      author: "Tom Nodine",
      firm:  (
        <>
          CEO and Founder at Human Life Expectancy Inc.,
          <br />
          Wilmette, Illinois, United States.
        </>
      ),
      image: Tom
    },
    {
      text: " I can see why you are successful. So many people have great ideas and stories to tell but they don't have the words. They don't understand the craft. In those many, many cases, this team can help them get a story told. I know I will recommend you! If creativity is not your thing and words are not your best asset, check out Kick’s services. They're great at what they do",
      author: "Christina Beauchemin",
      firm:  (
        <>
        Author, Speaker, Educator, Let My Legacy Be Love, LLC,
        <br />
        Malden Bridge, New York, United States.
      </>
      ),
      image: Christina
    },
    {
      text: "I believe, like being a composer, it is a gift to come up with a piece of writing that can move people to action- Only some people have this gift. I believe you are one of those people. For those who are not great at writing, Kick&co services are the answer to their problem. Oftentimes, telling our stories can be difficult. We want to put ourselves in the best light. We are a little too close to our own pain and pride to tell it in a way that is interesting to others. Having someone write it for us, great storytellers like the team here is a boon.",
      author: "Susan Ritter",
      firm:  (
        <>
        Founder, Wealthy Wise Woman,
        <br />
        Greater Tampa Bay Area.
      </>
      ),
      image: Susan
    },
    {
      text:  "I want to send a big appreciation to Kick&co, I never thought I'd write a book until last year considering I didn't even like reading until last year. Your help, expertise, and openness to work with me is greatly appreciated",
      author: "Travis Robbins",
      firm:  (
        <>
        Adventurer/Walker, The Connection Odyssey,
        <br />
        Watertown, South Dakota, United States.
      </>
      ),
      image: Travis
    },
    {
      text: "This is a great team! Very intellectual, and efficient, and serves as great accountability partners for projects you work on.",
      author: "Ag Goldsmith",
      firm:  (
        <>
        Finance Director, Hendrick Toyota Scion,
        <br />
        Charleston, South Carolina, United States.
      </>
      ),
      image: Ag
    },
    {
      text: "Kick, you walk the talk. Your range of services to help budding authors share their message with a wider audience is vital in a world of fake news, turmoil, and constant change. I always learn and am inspired when exploring the experiences of others who have faced challenges. It lifts both spirits and potential.",
      author: "Rebecca Jenkins",
      firm:  (
        <>
        Founder at RJEN,
        <br />
        Bristol, United Kingdom.
      </>
      ),
      image: Rebecca
    },
    {
      text:  "This team is an awesome team. Truly awesome. That word is overused, but you truly fill that word with meaning. It is truly an honor to read your work.",
      author: "Donnie McFall, MBA",
      firm:  (
        <>
        Owner of McFall Consulting Inc.,
        <br />
        Minneapolis, Minnesota, United States.
      </>
      ),
      image: Donnie
    },
    {
      text: "I love seeing a band of young people come together to create something and you have created something truly remarkable here; I happily recommend your services to people",
      author: "Douglas Eze, CFF",
      firm:  (
        <>
        Founder & CEO, Largo Financial Services LLC,
        <br />
        Washington DC-Baltimore Area, United States.
      </>
      ),
      image: Eze
    },
    {
      text: "Love the direction you went with the narrative, fantastic writing. Gosh, I can just imagine the types of people who would benefit from YOU telling their story, you’re creating artwork with words– fantastic. Keep up the good work.",
      author: "Megan Tribble",
      firm:  (
        <>
        Content Marketer and Coach,
        <br />
        Danville, Indiana, United States.
      </>
      ),
      image: Megan
    },
    {
      text: "Your experience turning ideas into profitable ventures is great. It's all about adding value and creating win-win partnerships. Providing this valuable service has been your key to success. Well done!",
      author: "David D. Doerrier",
      firm:  (
        <>
        Presentation Empowerment Coach David D. Doerrier, LLC,
        <br />
        Atlanta Metropolitan Area, United States.
      </>
      ),
      image: David
    },
    {
      text:  "Contextualization and personalization are what AI is lacking right now. There will always be a need for great creators like Kick’s",
      author: "Elizabeth Solaru",
      firm:  (
        <>
        Director, Luxury Business Emporium,
        <br />
        London Area, United Kingdom.
      </>
      ),
      image: Solaru
    },
    {
      text:  "Kick & co creations are not mere narratives; you'll be immersed in stories woven with depth. For those who demand extraordinary content and want to forge heartfelt connections with their audience, Kick&co is the pinnacle of partnership towards that goal.",
      author: "Ralph Danna P. A.",
      firm:  (
        <>
        Owner of Dannasales,
        <br />
        Spring Hill, Florida, United States.
      </>
      ),
      image: Ralph
    },
  
  ];

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    <>
<div className="relative w-full h-auto py-12 md:py-20 flex flex-col items-center font-playfair bg-cover bg-center  overflow-hidden"   style={{
        background:
          "linear-gradient(135deg, #0c145b, #737373, #002772)",
      }} >

      {/* <div className="text-center mb-0 md:mb-10">
        <button className="px-6 py-3 bg-[#2f3e6e] text-white rounded-full mb-0 md:mb-4 text-md md:text-lg">
          Testimonials
        </button>
      </div> */}

<div className="text-center mb-10 md:mb-10" data-aos="fade-up">
<span className="font-medium px-6 py-3 bg-[#1d3072] text-md md:text-lg 2xl:text-2xl text-white rounded-full">
Testimonials
        </span>
        {/* <h2 className="mt-6 text-2xl text-gray-800">
          A simple and streamlined process for building your content engine.
        </h2> */}
      </div>

      {/* Slider Content */}
      <div className="slider-content text-white text-center max-w-xs md:max-w-4xl py-1 md:py-16 flex flex-col items-center h-64 md:h-40" data-aos="fade-up">
  <div className="bg-gray-200 text-black p-6 rounded-lg shadow-md">
  <p className="text-xl mb-0 md:mb-6 relative min-h-24 md:min-h-20 ">
  <span className="absolute top-[-20px] left-[-15px] text-3xl md:text-6xl opacity-60 animate-fadeOut">
        <FontAwesomeIcon icon={faQuoteLeft} />
      </span>
      <span className='p-5 text-sm md:text-lg md:p-20'>
      {slides[currentSlide].text}

      </span>
      <span className="absolute right-0 text-3xl md:text-6xl opacity-60 animate-fadeOut">
        <FontAwesomeIcon className='text-black' icon={faQuoteRight} />
      </span>
    </p>
  </div>        
  <p className="text-md md:text-lg font-semibold mt-6 2xl:mt-6">{slides[currentSlide].author}</p>

  {/* Firm Text with Image */}
  <div className="flex items-center justify-center space-x-2 mt-2">
  <img
  key={slides[currentSlide].image} // Forces React to re-render the image smoothly
  src={slides[currentSlide].image}
  alt={slides[currentSlide].author}
  className="w-10 h-10 rounded-full transition-opacity duration-500 ease-in-out"
  loading="lazy"
/>
    <p className="text-xs md:text-sm text-gray-400">{slides[currentSlide].firm}</p>
  </div>
</div>


      {/* Navigation Arrows */}
      <div className="relative hidden md:flex items-center justify-center w-full max-w-screen-xl mx-auto" data-aos="zoom-in">
      <button
        onClick={handlePrev}
        className="absolute top-1/2 left-4 2xl:left-0 transform -translate-y-1/2 bg-black md:bg-white text-white md:text-black hover:text-white hover:bg-[#2f3e6e] hover:border px-4 py-2 rounded-md shadow-md z-10
"
        aria-label="Previous Slide"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button> 
      
      <button
        onClick={handleNext}
        className="absolute top-[105%] md:top-1/2 right-0 2xl:right-0 transform -translate-y-1/2 bg-black md:bg-white text-white md:text-black hover:text-white hover:bg-[#2f3e6e] hover:border px-4 py-2 rounded-md shadow-md z-10"
        aria-label="Next Slide"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
</div>



      {/* Pagination Indicators */}
      <div className="flex justify-center mt-96 md:mt-80" data-aos="zoom-in">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`mx-1 h-1 ${currentSlide === index ? 'w-20 bg-[#2f3e6e]' : 'w-4 bg-gray-400'} transition-all rounded hidden md:flex`}
          ></div>
        ))}
      </div>

      

      
    </div>
      <div className="md:hidden flex justify-center mt-10 md:mt-80 space-x-1" data-aos="zoom-in">
      {slides.map((_, index) => (
        <div
          key={index}
          className={`mx-1 h-1 ${currentSlide === index ? 'w-20 bg-[#2f3e6e]' : 'w-4 bg-gray-400'} transition-all rounded`}
        ></div>
      ))}
    </div>
    

      {/* Navigation Arrows */}
      <div className="relative md:hidden  flex items-center justify-center w-full max-w-screen-xl mx-auto" data-aos="zoom-in">
      <button
        onClick={handlePrev}
        className="absolute top-1/2 left-2 2xl:left-0 transform -translate-y-1/2 bg-[#2f3e6e] md:bg-white text-white md:text-black hover:text-white hover:bg-[#2f3e6e] hover:border px-4 py-2 rounded-md shadow-md z-10
"
        aria-label="Previous Slide"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button> 
      
      <button
        onClick={handleNext}
        className="absolute top-[105%] md:top-1/2 right-0 2xl:right-0 transform -translate-y-1/2 bg-[#2f3e6e] md:bg-white text-white md:text-black hover:text-white hover:bg-[#2f3e6e] hover:border px-4 py-2 rounded-md shadow-md z-10"
        aria-label="Next Slide"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
</div>



    </>
    
  );
};

export default TestimonialSlider;
