import React, { useRef, useEffect } from "react";
import HomeHero from "./HomeHero";
import TestimonialSlider from "./TestimonialSlider";
import Price from "./Price";
import Faq from "./Faq";
import Evolved from "./Evolved";
import HomeBanner from "./HomeBanner";
import ResponsiveCarousel from "./ResponsiveWorkflow";

const Home = () => {
  const priceRef = useRef(null);

  // Scroll to Price when URL changes
  useEffect(() => {
    if (window.location.hash === "#price" && priceRef.current) {
      priceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [window.location.hash]);

  return (
    <>
      <HomeHero />
      <ResponsiveCarousel />
      <TestimonialSlider />
      <div ref={priceRef}>
        <Price />
      </div>

      <Evolved />
      <Faq />
      <HomeBanner />
    </>
  );
};

export default Home;
