import { useState } from "react";
import { Dialog } from "@headlessui/react";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Image1 from "../../../Images/Screenshots/New4.jpg";
import Image2 from "../../../Images/Screenshots/New2.jpg";
import Image3 from "../../../Images/Screenshots/New3.jpg";
const cardsData = [
  { id: 1, title: "Strategy", paragraph1: "Define brand purpose", paragraph2: "Create market avatars", paragraph3: "Establish message frameworks", image: Image1 },
  { id: 2, title: "Curation", paragraph1: "Build content systems", paragraph2: "Optimize production and distribution", paragraph3: "Establish content lifecycle", image: Image2 },
  { id: 3, title: "Management", paragraph1: "Develop models for systems and content", paragraph2: "Draw insight from collated data", paragraph3: "Optimize production and distribution", image: Image3 },
];
const MobileWorkflow = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  const nextSlide = () => {
    setActiveIndex((prev) => (prev === cardsData.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setActiveIndex((prev) => (prev === 0 ? cardsData.length - 1 : prev - 1));
  };

  return (
    <div className="flex flex-col items-center justify-center w-full py-16 space-y-10 font-playfair">
      <div className="text-center mb-4">
        <span className="font-medium px-6 py-3 bg-[#2f3e6e] text-md md:text-lg 2xl:text-2xl text-white rounded-full" data-aos="fade-up">
          Our Workflow
        </span>
      </div>

      <div 
  className="flex flex-col md:flex-row items-start gap-1 w-11/12 justify-start bg-cover bg-center p-6 rounded-lg shadow-md" 
  style={{
    background:
      "linear-gradient(135deg, #0c145b, #737373, #002772)",
  }} >
  <img
    src={cardsData[activeIndex].image}
    alt={cardsData[activeIndex].title}
    className="w-full md:w-64 h-96 md:h-96 rounded-lg cursor-pointer object-contain"
    onClick={() => setSelectedImage(cardsData[activeIndex].image)}
  />
  <div className="text-white text-start w-full md:w-auto">
    <h3 className="text-2xl 2xl:text-4xl font-semibold mb-4">{cardsData[activeIndex].title}</h3>
    <p className="mt-2 text-sm">▪ {cardsData[activeIndex].paragraph1}</p>
    <p className="mt-2 text-sm">▪ {cardsData[activeIndex].paragraph2}</p>
    <p className="mt-2 text-sm">▪ {cardsData[activeIndex].paragraph3}</p>
  </div>
</div>


      <div className="flex space-x-10 mt-12">
        <button onClick={prevSlide} className="bg-[#2f3e6e] text-white hover:text-black hover:bg-white px-4 py-2 rounded-md shadow-md" aria-label="Previous Slide">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="flex space-x-2 mt-4">
          {cardsData.map((_, index) => (
            <div key={index} className={`h-1 ${activeIndex === index ? 'w-20 bg-[#2f3e6e]' : 'w-4 bg-gray-400'} transition-all rounded`}></div>
          ))}
        </div>
        <button onClick={nextSlide} className="bg-[#2f3e6e] text-white hover:text-black hover:bg-white px-4 py-2 rounded-md shadow-md" aria-label="Next Slide">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <Dialog.Panel className="bg-white p-4 rounded-lg max-w-lg">
          <img src={selectedImage} alt="Preview" className="w-full rounded-lg" />
          <button className="mt-4 p-2 bg-red-500 text-white rounded" onClick={() => setSelectedImage(null)}>Close</button>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default MobileWorkflow;
