import React from 'react';
import SidebarPostsConts from './SidebarPostsConts'; // Import the array of posts
import { NavLink } from "react-router-dom";


const SidebarPosts = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 font-playfair">
      <h2 className="text-md md:text-xl font-bold mb-4">Featured Posts</h2>
      <ul>
        {SidebarPostsConts.map((post, index) => (
          <li key={index} className="mt-8" style={{marginBottom: '37px'}}>
            <NavLink to={post.url} className="text-black-600 hover:underline font-bold">{post.title}</NavLink>
            <p className="text-gray-600  text-sm" style={{fontSize: '10px'}}>{post.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarPosts;
