import React from "react";
import { useState } from "react";
import Image1 from '../../Images/books/1.webp'
import Image2 from '../../Images/books/2.webp'
import Image3 from '../../Images/books/5.webp'
import Image4 from '../../Images/books/6.webp'
import Image5 from '../../Images/books/7.webp'
import Image7 from '../../Images/books/9.webp'
import Image8 from '../../Images/books/10.webp'
import Image9 from '../../Images/books/11.webp'
import Image10 from '../../Images/books/12.webp'
import Image11 from '../../Images/books/13.webp'
import Image12 from '../../Images/books/14.webp'

const books = [
    {
      id: 1,
      title: "The Pragmatic Programmer",
      author: "Andrew Hunt & David Thomas",
      image: Image1
    },
    {
      id: 2,
      title: "Clean Code",
      author: "Robert C. Martin",
      image: Image2
    },
    {
      id: 3,
      title: "You Don't Know JS",
      author: "Kyle Simpson",
      image: Image3
    },
    {
      id: 4,
      title: "Eloquent JavaScript",
      author: "Marijn Haverbeke",
      image: Image4
    },
    {
        id: 5,
        title: "The Pragmatic Programmer",
        author: "Andrew Hunt & David Thomas",
        image: Image5
      },
     
      {
        id: 7,
        title: "You Don't Know JS",
        author: "Kyle Simpson",
        image: Image7
      },
      {
        id: 8,
        title: "Eloquent JavaScript",
        author: "Marijn Haverbeke",
        image: Image8
      },
      {
        id: 9,
        title: "The Pragmatic Programmer",
        author: "Andrew Hunt & David Thomas",
        image: Image9
      },
      {
        id: 10,
        title: "Clean Code",
        author: "Robert C. Martin",
        image: Image10
      },
      {
        id: 11,
        title: "You Don't Know JS",
        author: "Kyle Simpson",
        image: Image11
      },
      {
        id: 12,
        title: "Eloquent JavaScript",
        author: "Marijn Haverbeke",
        image: Image12
      },
  ];
  
  const BooksGallery = () => {

    const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };


    return (
      <div
      className="py-44 w-full flex justify-center items-center"
      style={{
        background: "linear-gradient(135deg, #0c145b, #737373, #002772)",
      }}
    >
      <div className="max-w-6xl w-full p-4">
        <h2 className="text-2xl lg:text-4xl font-semibold text-center mb-6 lg:mb-20 text-white">
          Published Books
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {books.map((book) => (
            <div
              key={book.id}
              className="hover:scale-105 transition-transform duration-300"
            >
              <img
                src={book.image}
                alt={book.title}
                className="rounded-md object-cover w-full h-64 cursor-pointer"
                onClick={() => handleImageClick(book.image)}
              />
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleCloseModal}
        >
          <div
            className="relative"
            onClick={(e) => e.stopPropagation()} // Prevent clicking inside the modal from closing it
          >
            <img
              src={selectedImage}
              alt="Selected Book"
              className="max-w-full max-h-[90vh] rounded-md"
            />
            <button
              className="absolute top-3 right-3 p-3 text-white bg-black opacity-50 hover:opacity-100 rounded-lg"
              onClick={handleCloseModal}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
    );
  };
  
  export default BooksGallery;
  