const SidebarPostsConts = [
  {
    title:
      "How to Build a Profitable Personal Brand from Nothing",
    date: "March 21,2025",
    url: "/ktn/afroprenuership/how-to-build-a-profitable-personal-brand-from-nothing",
  },

  {
    title:
      "The Power of Data-Driven Communication in Executive Decision-Making",
    date: "March 14,2025",
    url: "/ktn/afroprenuership/the-power-of-data-driven-communication-in-executive-decision-making",
  },

  {
    title: "Creating New Business Opportunities Through Personal Branding",
    date: "March 07,2025",
    url: "/ktn/afroprenuership/creating-nNew-business-opportunities-through-personal-branding",
  },

  {
    title:
      "How Executives Can Enhance Investor Relations Through Effective Communication",
    date: "February 21,2025",
    url: "/ktn/afroprenuership/how-executives-can-enhance-investor-relations-through-effective-communication",
  },
  {
    title: "The Role of Ghostwriting in Scaling Thought Leadership",
    date: "February 14,2025",
    url: "/ktn/afroprenuership/the-role-of-ghostwriting-in-scaling-thought-leadership",
  },

  {
    title: "How Empathy Can Help Build You A Stronger Brand",
    date: "February 07,2025",
    url: "/ktn/afroprenuership/how-empathy-can-help-build-you-a-stronger-brand",
  },
];

export default SidebarPostsConts;
