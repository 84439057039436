import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaMicrophone, FaUsers, FaEye, FaClock, FaChartLine } from "react-icons/fa";
import YtImage from "../../Images/YtImage/A25ptF7GbRo-HD.jpg";

const Interact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Slower animation (1.5s)
      easing: "ease-in-out", // Smooth effect
      once: true, // Animates only once
    });
  }, []);

  const stats = [
    { value: "9000+", label: "Audience", icon: <FaUsers /> },
    { value: "600k+", label: "Views", icon: <FaEye /> },
    { value: "10K+", label: "Hours publishing", icon: <FaClock /> },
    { value: "1m+", label: "Converted opportunity", icon: <FaChartLine /> },
  ];

  return (
    <section
      className="max-w-7xl mx-auto px-6 py-10 md:py-20 grid gap-10 md:grid-cols-[1fr_1.5fr] items-start"
      data-aos="fade-up"
    >
      <div className="flex flex-col justify-between h-full">
        <div
          className="space-y-6 md:space-y-10"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <h2 className="text-3xl md:text-4xl text-gray-900">
            Feature on KTN, what’s your story?
          </h2>
          <a
            href="https://wjqwrc5sl98.typeform.com/to/IcU1t3V7"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="flex items-center mt-10 gap-2 text-white px-4 py-2 md:px-5 md:py-3 rounded-lg shadow-md transition-transform transform hover:scale-105"
              style={{
                background:
                  "linear-gradient(135deg, #0c145b, #737373, #002772)",
                transition: "background 0.3s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background =
                  "linear-gradient(90deg, #b5baa3, #949a84)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.background =
                  "linear-gradient(135deg, #0c145b, #737373, #002772)")
              }
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <FaMicrophone className="text-sm md:text-lg" /> Feature on KTN
            </button>
          </a>
        </div>
        <div
          className="grid grid-cols-2 gap-3 md:gap-4 mt-8 md:mt-auto"
          data-aos="fade-up"
          data-aos-delay="800"
        >
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-gray-100 p-3 md:p-4 rounded-lg shadow"
              data-aos="flip-left"
              data-aos-delay={1000 + index * 300} // Delayed for smooth staggered effect
            >
              <p className="text-base md:text-lg flex items-center gap-2">
                {stat.icon} {stat.value}
              </p>
              <p className="text-gray-600 text-xs md:text-sm">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="self-stretch" data-aos="fade-left" data-aos-delay="1300">
        <img
          src={YtImage}
          alt="Podcast Team"
          className="rounded-lg shadow-lg w-full h-auto md:h-full object-cover"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default Interact;
