import React, { useState } from "react";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, message } = formData;
    const subject = "Contact Form Submission";
    const body = `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;

    const mailtoLink = `mailto:info@kickandcosynergy.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const faqData = [
    {
      question: "What services does Kick & Co provide?",
      answer:
        "We specialize in communication and marketing solutions for visionary leaders and businesses. Our services include personal branding for executives, company-wide communication strategies, thought leadership content, legacy curation, media outreach, and integrated content ecosystems. Whether you're an executive, a company, or both, we help you craft compelling narratives that drive influence and impact.",
    },
    {
      question: "Who is an ideal client for Kick & Co?",
      answer:
        "Our ideal clients are leaders and businesses shaping the future. This includes executives looking to build authority and influence, companies aiming to establish a strong brand presence, and thought leaders or changemakers seeking to leave a lasting legacy. If you're ready to invest in high-quality, strategy-driven communication and marketing, we're ready to partner with you.",
    },
    {
      question: "How does Kick & Co help executives build their personal brand?",
      answer:
        "We work closely with executives to identify their unique strengths, values, and vision. Our services include crafting thought leadership content, managing social media, developing speeches and op-eds, and securing media and speaking opportunities. The goal is to position you as an industry authority and trusted voice while ensuring your narrative aligns with your personal and professional objectives.",
    },
    {
      question: "What industries does Kick & Co work with?",
      answer:
        "We have experience across a wide range of industries, including technology, finance, healthcare, consumer goods, professional services, and nonprofits. No matter your field, our team is adept at tailoring communication and marketing strategies to meet your unique needs and audience.",
    },
    {
      question: "How long does it take to see results?",
      answer:
        "The timeline for results depends on your goals and the services you select. While building a personal brand or transforming a company's communication strategy is a long-term investment, many clients begin to see measurable outcomes—like increased engagement, stronger positioning, and clearer messaging—within the first 3–6 months. We'll work with you to set realistic expectations and milestones.",
    },
    {
      question: "How can I get started with Kick & Co?",
      answer:
        "Getting started is easy! Simply click the 'Partner with Us' button on our website to answer a few quick questions about your needs. From there, we’ll review your responses and reach out with next steps, including a proposal or scheduling a discovery call. Together, we’ll craft a strategy that aligns with your goals and vision.",
    },
  ];

  return (
    <div className="bg-gray-200 min-h-screen flex flex-col items-center px-4 pt-32 md:pt-44 font-playfair">
      {/* Header Section */}
      <div className="text-center max-w-2xl mx-auto mb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4">Contact Us</h1>
        <p className="text-xl">
        Have a question, message or want to engage Kick & Co? Complete the form below, someone from our team will be in touch with you shortly.        </p>
      </div>

      {/* Contact Form */}
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl mb-12 text-xl">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name" className="block text-gray-700 font-medium">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Your Name"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Your Email"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-700 font-medium">
              Message
            </label>
            <textarea
              id="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Your Message"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-[#1d3072] text-white py-2 px-4 rounded-md hover:bg-[#949a84] hover:text-black hover:scale-105 transition duration-300"
          >
            Send Message
          </button>
        </form>
      </div>

      {/* Contact Info Box */}
      <div className="bg-white shadow-md rounded-lg text-black mt-10 p-6 w-full max-w-3xl flex flex-col md:flex-row items-start md:items-center justify-between">
        <div className="text-xl lg:text-2xl font-semibold mb-4 md:mb-0">Our email is active</div>
        <div className="text-sm lg:text-lg">
          <p className="mb-2">We’ll respond before you can say Jack Robinson</p>
          <a href="mailto:info@kickandcosynergy.com" className="font-medium underline">
            info@kickandcosynergy.com
          </a>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="max-w-7xl mx-auto p-6 lg:p-10 mt-10 font-playfair overflow-hidden">
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
    <div>
    <h2 className="text-3xl lg:text-4xl xl:text-6xl text-gray-900">FAQ</h2>
          <p className="mt-5 text-xl xl:text-2xl">
            Here are some of the most common questions<br />we get about working together.
          </p>
    </div>
    <div>
    {faqData.map((item, index) => (
            <div key={index} className="border-t border-gray-300 pt-10">
              <div
                className="flex justify-between items-center cursor-pointer text-gray-900"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg md:text-xl xl:text-2xl font-medium">{item.question}</h3>
                <span className="text-lg font-bold">{activeIndex === index ? "−" : "+"}</span>
              </div>
              {activeIndex === index && (
                <p className="mt-2 text-gray-700 xl:text-lg">{item.answer}</p>
              )}
            </div>
          ))}
    </div>
  </div>
</div>

    </div>
  );
};

export default ContactPage;
