import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";

const PartnershipOffers = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const [expanded, setExpanded] = useState(null);

  const services = [
    {
      title: "Agency Machine",
      description: "For Agencies with over 1000 agents",
      headerdescription:
        "Virtual assistance, Strategy, and systems for execution of media/prospect outreach and campaigns to facilitate all agents.",
      fulldescription: (
        <>
          <strong className="text-sm lg:text-lg">Industries:</strong><br />
          <br />▪ <strong>Real estate          </strong>
          <br />
          <br />▪ <strong>Insurance</strong>
          <br />
          <br />▪ <strong>Financial services & wealth management          </strong>
          <br />
          <br />▪ <strong>Healthcare & telemedicine
          </strong>
          <br />
          <br />▪ <strong>Technology & SaaS          </strong>
          <br />        </>
      ),
    },
    {
      title: "Executive Blueprint",
      description: "For Executives Alone",
      headerdescription:
        "Fully managed communications and content services designed exclusively for individual executives who want to establish authority, grow influence, and communicate their vision.",
      fulldescription: (
        <>
          <strong className="text-sm lg:text-lg">Deliverables:</strong><br />
          <br />▪ <strong>Executive Presence & Branding</strong>
          <br />
          <br />▪ <strong>Thought Leadership Content</strong>
          <br />
          <br />▪ <strong>Media & Speaking Engagements</strong>
          <br />
          <br />▪ <strong>Social Media Strategy & Management</strong>
          <br />
          <br />▪ <strong>Strategic Communications</strong>
          <br />
          <br />▪ <strong>Monthly Reporting</strong>
        </>
      ),
    },
    {
      title: "Enterprise Command",
      description: "For Companies Alone",
      headerdescription:
        "Comprehensive communication and marketing solutions for businesses aiming to position themselves as industry leaders and innovators.",
      fulldescription: (
        <>
          <strong className="text-sm lg:text-lg">Deliverables:</strong><br />
          <br />▪ <strong>Company-Wide Messaging</strong>
          <br />
          <br />▪ <strong>Team Branding & Enablement</strong>
          <br />
          <br />▪<strong>Content Ecosystem Management</strong>
          <br />
          <br />▪ <strong>Media Relations & Crisis Communications</strong>
          <br />
          <br />▪ <strong>Company Social Media Strategy</strong>
        </>
      ),
    },
    {
      title: "Synergy Advantage",
      description: (
        <>
          For Executives and Their Companies <br /> (or Companies & Their
          Executives)
        </>
      ),
      headerdescription:
        "Integrated solutions for executives and their businesses to create a seamless content ecosystem that amplifies both individual and organizational brands.",
      fulldescription: (
        <>
          <strong className="text-sm lg:text-lg">Deliverables:</strong>
          <br /> <br />▪ <strong>
            
            All Services in The Executive Blueprint
            </strong>
          <br />
          <br />▪ <strong> All Services in The Enterprise Command </strong> 
          <br />
          <br />▪ <strong>Integrated Content Ecosystem</strong>
          <br />
          <br />▪ <strong>Co-Branded Campaigns</strong>
          <br />
          <br />▪ <strong>Team Training & Workshops </strong>
        </>
      ),
    },
    {
      title: "Legacy Architect",
      description: "For Individuals Seeking to Curate Their Legacy",
      headerdescription:
        "A high-touch service for those building a legacy through written works, documented milestones, and a timeless personal brand.",
      fulldescription: (
        <>
          <strong className="text-sm lg:text-lg">Deliverables:</strong>
          <br />
          <br />▪ <strong>Legacy Branding Strategy</strong>
          <br />
          <br />▪ <strong>Book Development & Publishing</strong>
          <br />
          <br />▪ <strong>Documented Milestones </strong>
          <br />
          <br />▪ <strong>Philanthropic Positioning </strong>
          <br />
          <br />▪ <strong>Heritage Content Hub</strong>
          <br />
          <br />▪ <strong>Legacy Campaigns</strong>
        </>
      ),
    },
    {
      title: "Tailored offers",
      headerdescription:
        "Seasonal services for brands seeking specific expert delivery ",
      fulldescription: (
        <>
          <strong className="text-sm lg:text-lg">Services Include:</strong>
          <br />
          <br />
          <br />▪ <strong>Specialized Training Programs</strong>
          <br />
          <br />
          <br />▪ <strong>Global Market Expansion </strong>
        </>
      ),
    },
  ];

  return (
    <section className="max-w-7xl mx-auto px-8 py-20 text-center font-playfair">
      <div className="text-center mb-10 md:mb-20" data-aos="fade-down">
        <span className="font-medium px-6 py-3 bg-[#1d3072] text-md md:text-lg 2xl:text-2xl text-white rounded-full">
          Partnership Offers
        </span>
      </div>

      {/* Grid Layout */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-10"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.2 }}
        variants={{
          hidden: {},
          visible: { transition: { staggerChildren: 0.3 } },
        }}
      >
        {services.map((service, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, delay: index * 0.3 }}
            viewport={{ once: false, amount: 0.2 }}
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 10px 30px rgba(0,0,0, 0.2)",
            }}
            className={`w-full bg-white border rounded-2xl shadow-3xl transition-all duration-300 cursor-pointer ${
              index === services.length - 1 && services.length % 2 !== 0
                ? "md:col-span-2 md:max-w-md md:mx-auto"
                : ""
            }`}
            onClick={() => setExpanded(expanded === index ? null : index)}
          >
            {/* Top Section with Background */}
            <div
              className="text-white p-7 lg:p-14 rounded-t-2xl  justify-between items-center"
              style={{
                background:
                  "linear-gradient(135deg, #0c145b, #737373, #002772)",
              }}
            >
              <h3 className="text-lg md:text-3xl font-bold">{service.title}</h3>
            </div>

            {/* Content Section */}
            <div className="p-8">
              <p className="text-md md:text-lg text-gray-600">
                {service.description}
              </p>

              {expanded === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  transition={{ duration: 0.3 }}
                  className="mt-4 text-gray-700 pl-1 lg:pl-6 p-2 lg:p-6 rounded-lg"
                  style={{
                    background:
                      "linear-gradient(135deg, #0c145b, #737373, #002772)",
                  }}
                >
                  <p className="text-xs md:text-md mb-10 text-white">
                    {service.headerdescription}
                  </p>
                  <div className="mt-2 text-xs md:text-md text-start text-white">
                    {service.fulldescription}
                  </div>
                </motion.div>
              )}

              <button className="text-[#1d3072] text-sm md:text-md font-semibold mt-2 focus:outline-none">
                {expanded === index ? "Show Less ↑" : "Read More ↓"}
              </button>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <div className="text-center mt-20">
        <a
          href="https://wjqwrc5sl98.typeform.com/to/PausBFZl"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex"
        >
          <button className="text-xl font-playfair bg-[#1d3072] text-white sm:text-base md:text-xl 2xl:text-2xl px-5 py-3 rounded-md hover:brightness-110 transition duration-300 will-change-transform hover:bg-[#949a84] hover:text-black hover:scale-105">
            Engage
          </button>
        </a>
      </div>
    </section>
  );
};

export default PartnershipOffers;
