import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../Images/ktnLogo/logo_ktn.jpg";
import Image2 from "../../Images/ktnLogo/new.png";
import Image3 from "../../Images/ktnLogo/ktn_plus.png";
import One from "../../Images/kt/1.webp";
import Two from "../../Images/kt/2.webp";
import Three from "../../Images/kt/3.webp";
import Four from "../../Images/kt/4.webp";
import Five from "../../Images/kt/5.webp";
import Six from "../../Images/kt/6.webp";
import Seven from "../../Images/kt/7.webp";
import Eight from "../../Images/kt/8.webp";
import Nine from "../../Images/kt/9.webp";
import Ten from "../../Images/kt/10.webp";
import Eleven from "../../Images/kt/11.webp";
import Twelve from "../../Images/kt/12.webp";
import Thirteen from "../../Images/kt/13.webp";
import Fourteen from "../../Images/kt/14.webp";
import Fifteen from "../../Images/kt/15.webp";
import Sixteen from "../../Images/kt/16.webp";
import Seventeen from "../../Images/kt/17.webp";
import Eighteen from "../../Images/kt/18.webp";
import Nineteen from "../../Images/kt/19.webp";
import Twenty from "../../Images/kt/20.webp";
import TwentyOne from "../../Images/kt/21.webp";
import TwentyTwo from "../../Images/kt/22.webp";
import TwentyThree from "../../Images/kt/23.webp";
import TwentyFour from "../../Images/kt/24.webp";
import TwentyFive from "../../Images/kt/25.webp";
import TwentySix from "../../Images/kt/26.webp";
import TwentySeven from "../../Images/kt/27.webp";
import TwentyEight from "../../Images/kt/28.webp";
import TwentyNine from "../../Images/kt/29.webp";
import './Kicktn.css'
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
// import { Autoplay } from 'swiper/modules';
import {
  FaLinkedinIn,
  FaInstagram,
  FaSpotify,
  FaPodcast,
  FaYoutube,
} from "react-icons/fa";
import InteractHome from "../Interact/InteractHome";

const images  = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
  TwentyTwo,
  TwentyThree,
  TwentyFour,
  TwentyFive,
  TwentySix,
  TwentySeven,
  TwentyEight,
  TwentyNine,
];
const textVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: { delay: i * 0.2, duration: 0.5, ease: "easeOut" },
  }),
};


const cardVariants = {
  hidden: { opacity: 0, x: -70 }, // Start further left
  visible: (i) => ({
    opacity: 1,
    x: 0, // Slide to original position
    transition: { delay: i * 0.5, duration: 1.2, ease: "easeInOut" }, // Slower and smoother
  }),
};

const Kicktn = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const [iconSize, setIconSize] = useState(10);

  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth >= 1024 ? 24 : 16);
    };

    handleResize(); // Set initial size
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Adjust duration as needed
  }, []);
  

  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 1280) { // Apply only on mobile (adjust breakpoint if needed)
        const scrollY = window.scrollY;
        const fadeOutPoint = 100; 
        const newOpacity = Math.max(1 - scrollY / fadeOutPoint, 0);
        setOpacity(newOpacity);
      } else {
        setOpacity(1); // Keep it fully visible on desktop
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <><section
      className="relative py-10 md:py-20 px-6 bg-cover bg-center font-playfair"
      style={{
        background: "linear-gradient(135deg, #0c145b, #737373, #002772)",
      }}
    >
      <div className="absolute inset-0 bg-black/60"></div>

      <div
        className="fixed left-0 top-1/3 flex flex-col items-center shadow-lg rounded-lg p-4 z-50 transition-opacity duration-300"
        style={{
          opacity,
          background: "linear-gradient(135deg, #0c145b, #737373, #002772)",
        }}
      >
        <a
          href="https://open.spotify.com/show/68Ii6MIniyA9NaOR4w975c"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-700 p-3 rounded-full shadow-lg transition"
        >
          <FaSpotify size={iconSize} className="text-gray-400 transition-transform duration-300 hover:scale-125" />
        </a>
        <div className="w-full h-[1px] bg-gray-300 my-2"></div>
        <a
          href="https://podcasts.apple.com/us/podcast/kick-the-narrative/id1796561728"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-700 p-3 rounded-full shadow-lg transition"
        >
          <FaPodcast size={iconSize} className="text-gray-400 transition-transform duration-300 hover:scale-125" />
        </a>
        <div className="w-full h-[1px] bg-gray-300 my-2"></div>
        <a
          href="https://www.youtube.com/@kickthenarrative"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-700 p-3 rounded-full shadow-lg transition"
        >
          <FaYoutube size={iconSize} className="text-gray-400 transition-transform duration-300 hover:scale-125" />
        </a>
        <div className="w-full h-[1px] bg-gray-300 my-2"></div>
        <a
          href="https://www.instagram.com/kick.thenarrative/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-700 p-3 rounded-full shadow-lg transition"
        >
          <FaInstagram size={iconSize} className="text-gray-400 transition-transform duration-300 hover:scale-125" />
        </a>
        <div className="w-full h-[1px] bg-gray-300 my-2"></div>
        <a
          href="https://www.linkedin.com/company/kickandcosynergy/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-700 p-3 rounded-full shadow-lg transition"
        >
          <FaLinkedinIn size={iconSize} className="text-gray-400 transition-transform duration-300 hover:scale-125" />
        </a>
      </div>


      <div className="relative max-w-7xl mx-auto text-white">
        <div className="mb-16 max-w-4xl mx-auto text-center mt-20">
          <motion.h2
            className="text-2xl xl:text-5xl font-extrabold leading-tight tracking-tigh text-gray-300 t"
            variants={textVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            custom={4}
          >
            Kick The Narrative (KTN)
            is  <br />our In-House Media Machine
          </motion.h2>
        </div>




        {/* <Swiper
  spaceBetween={20} // Adds space between slides
  loop={true}
  autoplay={{ delay: 700, disableOnInteraction: false }}
  modules={[Autoplay]}
  className="w-full"
  breakpoints={{
    320: { slidesPerView: 2 }, // 1 slide on mobile
    768: { slidesPerView: 2 }, // 2 slides on tablets
    1024: { slidesPerView: 4 }, // 3 slides on desktop
  }}
>
  {images.map((img, index) => (
    <SwiperSlide key={index} className="flex justify-center">
   <img
key={index}
onClick={() => setSelectedImage(img)}
src={img}
alt={`Slide ${index + 1}`}
className="w-96 h-96 object-cover rounded-xl shadow-lg cursor-pointer"
loading="lazy"
/>

    </SwiperSlide>
  ))}
</Swiper> */}
        <div className="w-full max-w-7xl bg-opacity-90 overflow-hidden relative">
          <div className="flex items-center gap-10 md:gap-20 animate-marquee" style={{ animationDuration: "90s" }}>
            {[...Array(2)].map((_, repeatIndex) => images.map((icon, index) => (
              <img
                key={`${repeatIndex}-${index}`}
                src={icon}
                alt={`Logo ${index + 1}`}
                className="w-96 h-96 object-cover rounded-xl shadow-lg cursor-pointer"
                loading="lazy" />
            ))
            )}
          </div>
        </div>








        <motion.p
          className="text-md lg:text-xl mt-7 lg:mt-20 leading-relaxed text-gray-300 text-start"
          variants={textVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          custom={5}
        >
          With KTN, we:
        </motion.p>

        <ul className="mt-2 lg:mt-6 space-y-5 text-xs lg:text-lg max-w-lg">
          {[
            "• Experiment with communication and marketing  strategies.",
            "• Share what we learn after execution and feedback.",
            "• Showcase our philosophies and systems.",
          ].map((text, i) => (
            <li
              key={i}
              className="flex items-start gap-4 text-left text-gray-300"
              data-aos="fade-right"
              data-aos-delay={i * 200} // Stagger the animation
            >
              <span className="font-medium">{text}</span>
            </li>
          ))}
        </ul>


        <div className="mt-10 lg:mt-20 grid grid-cols-1 md:grid-cols-3 gap-12">
          {[
            {
              title: "KTN CHANNEL",
              img: Image2,
              desc: "Kick & Co's video journal, features content library with video series, KTN podcast, and original scripted programming.",
              but: "Watch KTN",
              link: "https://www.youtube.com/@KICKTHENARRATIVE",
            },
            {
              title: "KTN BLOG",
              img: Image1,
              desc: "Kick & Co’s blog features insight shared in 3 distinct categories.",
              but: "Read KTN",
              link: "/readktn",
            },
            {
              title: "KTN PLUS",
              img: Image3,
              desc: "Behind the $4.99/month paywall, you’ll access weekly assets to make your brand grow relevance, revenue, and reach.",
              but: "Join Waitlist",
              link: "https://wjqwrc5sl98.typeform.com/to/f6kAlycr",
            },
          ].map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.div
                className="relative group rounded-3xl shadow-2xl overflow-hidden bg-gray-900 text-white hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={index}
              >
                <div className="relative w-full h-80">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110" />
                  <div className="absolute inset-0 bg-black/70"></div>
                </div>

                <div className="absolute bottom-6 left-6 right-6">
                  <h3 className="text-xl lg:text-3xl font-semibold">
                    {item.title}
                  </h3>
                  <p className="mt-3 text-sm lg:text-md text-gray-200">
                    {item.desc}
                  </p>
                  <button className="mt-5 px-6 py-3 bg-white text-gray-900 font-semibold rounded-full shadow-lg hover:bg-gray-200 transition-all">
                    {item.but}
                  </button>
                </div>

                <div className="absolute top-0 left-0 w-20 h-20 bg-yellow-400 opacity-30 blur-3xl rounded-full group-hover:opacity-70"></div>
              </motion.div>
            </a>
          ))}
        </div>
      </div>

      {/* Fullscreen Image Modal */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50"
          onClick={() => setSelectedImage(null)}
        >
          <img
            src={selectedImage}
            alt="Fullscreen"
            className="max-w-full max-h-full rounded-lg shadow-2xl" />
        </div>
      )}
    </section><InteractHome /></>
  );
};

export default Kicktn;
