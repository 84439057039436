import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaChevronDown } from "react-icons/fa";
import Team from "../../Images/Evolved/team_leads.webp";
import KickLogo from "../../Images/KickLogo/kick-removebg-preview.png";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import axios from "axios";


const Career = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  // const [filters, setFilters] = useState({
  //   region: "All",
  //   location: "All",
  //   department: "All",
  //   company: "All",
  // });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;

    const apiKey = "mc5E05u5e4O7IlLiRJhm5w";
    const formId = "7263348";

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${formId}/subscribe`,
        {
          api_key: apiKey,
          email: email,
          first_name: name,
        }
      );

      if (response.data.subscription) {
        alert("You have successfully subscribed!");
      } else {
        alert("Subscription failed. Please try again.");
      }
    } catch (error) {
      alert("Error occurred. Please try again later.");
    }
  };

  return (
    <>
      <section
        className="relative text-white py-16 px-3 sm:px-8 font-playfair w-full overflow-hidden"
        style={{
          background: "linear-gradient(135deg, #0c145b, #737373, #002772)",
        }}
      >
        {/* Large outlined "CAREERS" text */}
        <h1
    
          className="absolute top-32 lg:top-44 left-1/2 transform -translate-x-1/2 text-3xl sm:text-[1rem] md:text-[2rem] lg:text-[4rem] font-bold tracking-wide uppercase text-gray-300"
        >
          CAREERS
        </h1>

        <div
          className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center gap-0 lg:gap-12 mt-20 lg:mt-56 px-4"
          data-aos="fade-up"
        >
          {/* Image */}
          <img
            src={KickLogo}
            alt="Acolyte"
            className="w-full max-w-xs sm:max-w-md lg:w-1/2"
            data-aos="fade-right"
          />

          {/* Text Content */}
          <div className="lg:w-1/2" data-aos="fade-left">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 border-b border-white pb-2">
              Who We Are
            </h2>
            <p className="text-base sm:text-lg leading-relaxed">
              Kick & Co is a team of believers who understand the necessity of
              properly representing narratives.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed mt-4">
              We provide communication and marketing solutions for builders of
              tomorrow because we know what it means to kick against the current
              and defy the negative narratives tied to one’s time and identity.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed mt-4">
              Our mission to be the acolytes of change-makers stems from our own
              journey.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed mt-4">
              Revolutionary leaders inspire and influence with compelling
              narratives—rooted in their lived experiences—to craft legacies
              that shape the future.
            </p>
          </div>
        </div>

        <div
          className="max-w-6xl mx-auto flex flex-col-reverse lg:flex-row items-center gap-12 px-4 mt-10 lg:mt-20"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2" data-aos="fade-right">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 border-b border-white pb-2">
              Grow With Us
            </h2>

            <p className="text-base sm:text-lg leading-relaxed">
              At Kick & Co, we believe in growth that defies limitations. Just
              as we help change-makers shape their narratives, we invite you to
              grow with us—challenging norms, pushing boundaries, and amplifying
              impact.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed">
              Whether you're a visionary leader, a bold storyteller, or a
              builder of tomorrow, we are here to equip you with the tools and
              strategies to thrive. Together, we can rewrite narratives, spark
              transformation, and leave a lasting legacy.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed">
              Join us in the journey of growth, where every step forward is a
              step toward shaping the future.
            </p>
            <br />
          </div>

          {/* Image */}
          <img
            src={Team}
            alt="Acolyte"
            className="w-full max-w-xs sm:max-w-md lg:w-1/2"
            data-aos="fade-left"
          />
        </div>

        {/* Quote Section */}
        <div className="flex items-center justify-center mt-10 px-1 lg:px-4">
  <div
    className="font-kalu text-md sm:text-lg lg:text-2xl xl:text-3xl font-semibold text-center max-w-4xl tracking-wide text-gray-300"
    data-aos="zoom-in"
  >
    <FaQuoteLeft className="inline text-gray-400 text-lg sm:text-base mr-2 relative -top-4" />
    Attitude is more important than talent here.
    <FaQuoteRight className="inline text-gray-400 text-lg sm:text-base ml-2 relative -top-2" />
    <br />
    <span className="font-bold">– Kalu Kalu</span>
  </div>
</div>

      </section>

      <div
        className="max-w-6xl mx-auto px-4 sm:px-6 py-10"
        data-aos="fade-up"
      >
        {/* Header */}
        <h1 className="text-5xl sm:text-6xl lg:text-8xl font-bold tracking-tight text-gray-900 uppercase opacity-10 leading-none text-center">
          WORK WITH US
        </h1>

        {/* Filters */}
        <div className="mt-10" data-aos="fade-up">
          <h2 className="text-lg sm:text-xl font-semibold">Opportunities</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 border-b pb-2 mt-4 text-gray-900">
            {["Department"].map((filter, index) => (
              <div
                key={index}
                className="flex items-center gap-2 text-sm font-medium"
              >
                {filter}
                <FaChevronDown className="text-gray-600 cursor-pointer" />
                {/* <span className="text-blue-200 italic">
                  {filters[filter.toLowerCase()]}
                </span> */}
              </div>
            ))}
          </div>
        </div>

        {/* No Open Positions */}
        <div
          className="mt-6 text-center text-gray-600 text-md font-medium"
          data-aos="fade-in"
        >
          We’re always looking to hire superstars to bolster our teams, but there’s no open positions right now.
        </div>
        <div className="flex flex-col items-center">
  <p className="text-md  mb-4 text-center max-w-2xl mt-16">
    You can subscribe to our newsletter, we typically announce our job listings there.
  </p>
  <form className="flex flex-col space-y-3 w-96" onSubmit={handleSubmit}>
    <input
      type="text"
      name="name"
      placeholder="Enter your name"
      className="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <input
      type="email"
      name="email"
      placeholder="Enter your email"
      className="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <button
      type="submit"
      className="bg-[#1d3072] text-white py-2 rounded-md hover:bg-[#949a84] hover:text-black hover:scale-105 transition duration-300"
    >
      Subscribe
    </button>
  </form>
</div>
     </div>
    </>
  );
};

export default Career;
