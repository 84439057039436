
import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Dialog } from "@headlessui/react";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Image1 from "../../../Images/Screenshots/New4.jpg";
import Image2 from "../../../Images/Screenshots/New2.jpg";
import Image3 from "../../../Images/Screenshots/New3.jpg";

const cardsData = [
  { id: 1, title: "Strategy", paragraph1: "Define brand purpose", paragraph2: "Create market avatars", paragraph3: "Establish message frameworks", image: Image1 },
  { id: 2, title: "Curation", paragraph1: "Build content systems", paragraph2: "Optimize production and distribution", paragraph3: "Establish content lifecycle", image: Image2 },
  { id: 3, title: "Management", paragraph1: "Develop models for systems and content", paragraph2: "Draw insight from collated data", paragraph3: "Optimize production and distribution", image: Image3 },
];

const DesktopWorkflow = () => {
     useEffect(() => {
        AOS.init({ duration: 1000, once: true });
      }, []);
      

  const [activeIndex, setActiveIndex] = useState(0);
  const [cards, setCards] = useState(cardsData);
  const [selectedImage, setSelectedImage] = useState(null);

  const nextSlide = () => {
    setCards((prev) => [...prev.slice(1), prev[0]]);
    setActiveIndex((prev) => (prev === cards.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCards((prev) => [prev[prev.length - 1], ...prev.slice(0, prev.length - 1)]);
    setActiveIndex((prev) => (prev === 0 ? cards.length - 1 : prev - 1));
    
  };
  

  return (
    <div className="flex flex-col items-center justify-center w-full py-32 space-y-20 overflow-hidden font-playfair">
      <div className="text-center mb-10" data-aos="fade-up">
        <span className="font-medium px-6 py-3 bg-[#1d3072] text-md md:text-lg 2xl:text-2xl text-white rounded-full" data-aos="fade-up">
          Our Workflow
        </span>
      </div>

      <div className="flex items-center gap-6 w-full justify-center" data-aos="fade-up">
        {cards.map((card, index) => (
          <div
            key={card.id}
            className={`p-6 w-[650px] h-[450px] rounded-lg shadow-md flex items-center space-x-4  duration-300 relative bg-cover bg-center ${index === 1 ? 'scale-110' : 'scale-90'}`}
            style={{
              background:
                "linear-gradient(135deg, #0c145b, #737373, #002772)",
            }} 
          >
            <div className="flex-3 2xl:flex-1 text-white">
              <h3 className="text-2xl 2xl:text-4xl font-semibold mb-8">{card.title}</h3>
              <p className="mt-2 text-sm xl:text-md">▪ {card.paragraph1}</p>
              <p className="mt-2 text-sm xl:text-md">▪ {card.paragraph2}</p>
              <p className="mt-2 text-sm xl:text-md">▪ {card.paragraph3}</p>
            </div>
            <div className="relative w-64 h-96">
  {/* Image */}
  <img
    src={card.image}
    alt={card.title}
    className="w-full h-full rounded-lg cursor-pointer object-contain"
    onClick={() => setSelectedImage(card.image)}
  />

</div>

          </div>
        ))}
      </div>

      <div className="flex space-x-10 mt-12"  data-aos="zoom-in">
        <button onClick={prevSlide} className="bg-[#2f3e6e] text-white hover:text-black hover:bg-white px-4 py-2 rounded-md shadow-md z-10" aria-label="Previous Slide">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="flex space-x-2 mt-4">
          {cards.map((_, index) => (
            <div key={index} className={`h-1 ${activeIndex === index ? 'w-20 bg-[#1d3072]' : 'w-4 bg-gray-400'} transition-all rounded`}></div>
          ))}
        </div>
        <button onClick={nextSlide} className="bg-[#1d3072] text-white hover:text-black hover:bg-white px-4 py-2 rounded-md shadow-md z-10" aria-label="Next Slide">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
  <Dialog.Panel className="bg-white p-4 rounded-lg max-w-lg">
    <img src={selectedImage} alt="Preview" className="w-full rounded-lg" />
    <button className="mt-4 p-2 bg-red-500 text-white rounded" onClick={() => setSelectedImage(null)}>Close</button>
  </Dialog.Panel>
</Dialog>

    </div>
  );
};

export default DesktopWorkflow;
