import React from 'react'
import Interact from './Interact'
import IntereactNewsletter from './IntereactNewsletter'

const InteractHome = () => {
  return (
  <><Interact /><IntereactNewsletter /></>
  )
}

export default InteractHome
