import React, { useEffect } from "react";
import "@fontsource/roboto-flex";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Nav from "./Components/Navbar/Nav";
import ReadKtn from "./Components/Ktn/ReadKtn";
import Home from "./Components/Home/NewHome/Home";
import Contact from "./Components/Contact/Contact";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Footer from "./Components/Home/NewHome/Footer";
// import Mouse from "./Components/Home/NewHome/Mouse";
import NewsletterPopup from "./Components/Newsletter/NewsletterPopup";
import Kicktn from "./Components/KtnPlus/Kicktn";
import CookieBanner from "./Components/Cookie/CookieBanner";
import CusttomerCentricMarketing from "./Components/Ktn/ThoughtsToChop/CusttomerCentricMarketing";
import PracticalStrategies from "./Components/Ktn/ThoughtsToChop/PracticalStrategies";
import OvercomingImposterSyndrome from "./Components/Ktn/ThoughtsToChop/OvercomingImposterSyndrome";
import TheTruth from "./Components/Ktn/ThoughtsToChop/TheTruth";
import TellYourStory from "./Components/Ktn/ThoughtsToChop/TellYourStory";
import Talkatives from "./Components/Ktn/ThoughtsToChop/Talkatives";
import RobtheWorld from "./Components/Ktn/ThoughtsToChop/RobtheWorld";
import ParentsChildren from "./Components/Ktn/ThoughtsToChop/ParentsChildren";
import MeditationInsomnia from "./Components/Ktn/ThoughtsToChop/MeditationInsomnia";
import Gym from "./Components/Ktn/ThoughtsToChop/Gym";
import OriginalLife from "./Components/Ktn/ThoughtsToChop/OriginalLife";
import GrowYourConfidence from "./Components/Ktn/ThoughtsToChop/GrowYourConfidence";
import MasteringColdPitching from "./Components/Ktn/MarketingAndSales/MasteringColdPitching";
import BuildingStableIncome from "./Components/Ktn/MarketingAndSales/BuildingStableIncome";
import PersuasiveStrategies from "./Components/Ktn/MarketingAndSales/PersuasiveStrategies";
import CraftingColdEmails from "./Components/Ktn/MarketingAndSales/CraftingColdEmails";
import SucceedBusiness from "./Components/Ktn/MarketingAndSales/SucceedBusiness";
import PracticalStrategiesJourney from "./Components/Ktn/MarketingAndSales/PracticalStrategies";
import LumpSumProjects from "./Components/Ktn/MarketingAndSales/LumpSumProjects";
import MarketingLaw from "./Components/Ktn/MarketingAndSales/MarketingLaw";
import FeelingOfSelling from "./Components/Ktn/MarketingAndSales/FeelingOfSelling";
import RetainClients from "./Components/Ktn/MarketingAndSales/RetainClients";
import Whore from "./Components/Ktn/MarketingAndSales/Whore";
import ColdOutreachStrategy from "./Components/Ktn/MarketingAndSales/ColdOutreachStrategy";
import IncreasePercievedValue from "./Components/Ktn/MarketingAndSales/IncreasePercievedValue";
import DebunkingMyths from "./Components/Ktn/Afroprenuership/DebunkingMyths";
import CraftCompellingContent from "./Components/Ktn/Afroprenuership/CraftCompellingContent";
import SevenStrategies from "./Components/Ktn/Afroprenuership/SevenStrategies";
import UnderstandingYourRights from "./Components/Ktn/Afroprenuership/UnderstandingYourRights";
import ShapePublicContent from "./Components/Ktn/Afroprenuership/ShapePublicContent";
import PerfectGhostwriterProject from "./Components/Ktn/Afroprenuership/PerfectGhostwriterProject";
import ValueSells from "./Components/Ktn/Afroprenuership/ValueSells";
import PowerfulPlayfulPersistence from "./Components/Ktn/Afroprenuership/PowerfulPlayfulPersistence";
import GrowYourBusiness from "./Components/Ktn/Afroprenuership/GrowYourBusiness";
import MasterPricing from "./Components/Ktn/Afroprenuership/MasterPricing";
import MasteringArtGhostwriting from "./Components/Ktn/Afroprenuership/MasteringArtGhostwriting";
import EmbracingUncertainty from "./Components/Ktn/Afroprenuership/EmbracingUncertainty";
import CashCow from "./Components/Ktn/Afroprenuership/CashCow";
import TrialAndError from "./Components/Ktn/Afroprenuership/TrialAndError";
import ColdOutreachAfro from "./Components/Ktn/Afroprenuership/ColdOutreachAfro";
import OvercomeImposterSyndromeAfro from "./Components/Ktn/Afroprenuership/OvercomeImposterSyndromeAfro";
import TalentLeverage from "./Components/Ktn/Afroprenuership/TalentLeverage";
import BillClientsAfro from "./Components/Ktn/Afroprenuership/BillClientsAfro";
import DontNiche from "./Components/Ktn/Afroprenuership/DontNiche";
import FAQ from "./Components/Faq/Faq";
import EffectivelyGhostwriter from "./Components/Ktn/Afroprenuership/EffectivelyGhostwriter";
import HighValueClients from "./Components/Ktn/Afroprenuership/HighValueClients";
import GhostwriteSeoContent from "./Components/Ktn/Afroprenuership/GhostwriteSeoContent";
import OvercomeWritersBlock from "./Components/Ktn/Afroprenuership/OvercomeWritersBlock";
import EstablishYourself from "./Components/Ktn/Afroprenuership/EstablishYourself";
import HighPerformanceTeam from "./Components/Ktn/Afroprenuership/HighPerformanceTeam";
import GhostwritingForAuthors from "./Components/Ktn/Afroprenuership/GhostwritingForAuthors";
import PricingPage from "./Components/Pricing/PricingPage";
import GhostwritingInterviews from "./Components/Ktn/Afroprenuership/GhostwritingInterviews";
import PersonalBrand from "./Components/Ktn/Afroprenuership/PersonalBrand";
import FiveSigns from "./Components/Ktn/Afroprenuership/FiveSigns";
import TopSkill from "./Components/Ktn/Afroprenuership/TopSkill";
import WritingABook from "./Components/Ktn/Afroprenuership/WritingABook";
import KeyLessons from "./Components/Ktn/Afroprenuership/KeyLessons";
import HelpClientChoose from "./Components/Ktn/Afroprenuership/HelpClientChoose";
import TellYourStoryAfro from "./Components/Ktn/Afroprenuership/TellYourStoryAfro";
import EffectiveTips from "./Components/Ktn/Afroprenuership/EffectiveTips";
import ReadingGhostwriters from "./Components/Ktn/Afroprenuership/ReadingGhostwriters";
import CreateLongTermClient from "./Components/Ktn/Afroprenuership/CreateLongTermClient";
import TurningClient from './Components/Ktn/Afroprenuership/TurningClient';
import OvercomeAnxiety from './Components/Ktn/Afroprenuership/OvercomeAnxiety';
import EarnMoreMoney from './Components/Ktn/Afroprenuership/EarnMoreMoney';
import GhostwritingOffer from './Components/Ktn/Afroprenuership/GhostwritingOffer';
import HelpGhostwriters from './Components/Ktn/Afroprenuership/HelpGhostwriters';
import TopExecutive from './Components/Ktn/Afroprenuership/TopExecutive';
import BestSelling from './Components/Ktn/Afroprenuership/BestSelling';
import TenSigns from './Components/Ktn/Afroprenuership/TenSigns';
import IdeaPublishing from './Components/Ktn/Afroprenuership/IdeaPublishing';
import SuccessPartnered from './Components/Ktn/Afroprenuership/SuccessPartnered';
import Career from "./Components/Career/Career";
import GhostwriterScaling from "./Components/Ktn/Afroprenuership/GhostwriterScaling";
import Books from "./Components/Books/Books";
import ExecutiveEnchance from "./Components/Ktn/Afroprenuership/ExecutiveEnchance";
import ShortForm from "./Components/Ktn/Afroprenuership/ShortForm";
import StrongerBrand from "./Components/Ktn/Afroprenuership/StrongerBrand";
import MediaExecutive from "./Components/Ktn/Afroprenuership/MediaExecutive";
import HumanCenteredMarketing from "./Components/Ktn/Afroprenuership/HumanCenteredMarketing";
import LeadershipBranding from "./Components/Ktn/Afroprenuership/LeadershipBranding";
import SeoThought from "./Components/Ktn/Afroprenuership/SeoThought";
import SmartExecutive from "./Components/Ktn/Afroprenuership/SmartExecutive";
import ScrollToTop from "./ScrollToTop";
import BusinessOpportunities from "./Components/Ktn/Afroprenuership/BusinessOpportunities";
import DataDriven from "./Components/Ktn/Afroprenuership/DataDriven";
import InteractHome from "./Components/Interact/InteractHome";
import ProfitablePersonal from "./Components/Ktn/Afroprenuership/ProfitablePersonal";
import BestLeaders from "./Components/Ktn/Afroprenuership/BestLeaders";

const TRACKING_ID = "G-QHY6FFWDGP";

function App() {
  // Initialize ReactGA and track page views
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);
  };

  const AppContent = () => {
    usePageTracking();

    return (
      <div>
        <ScrollToTop />
        {/* Add Custom Cursor */}
        {/* <Mouse /> */}
        {/* Navbar is always visible */}
        <Nav />
        {/* Define Routes for specific pages */}
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/books-published" exact element={<Books />} />
          <Route path="/readktn" exact element={<ReadKtn />} />
          <Route path="/ktn" exact element={<Kicktn />} />
          <Route path="/contact-us" exact element={<Contact />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/career" exact element={<Career />} />
          <Route
          path="/ktn/thought-to-chop/customer-centric-marketing"
          exact
          element={<CusttomerCentricMarketing />}
        />
        <Route
          path="/ktn/thought-to-chop/speak-with-confidence"
          exact
          element={<PracticalStrategies />}
        />
        <Route
          path="/ktn/thought-to-chop/overcoming-imposter-syndrome"
          exact
          element={<OvercomingImposterSyndrome />}
        />
        <Route
          path="/ktn/thought-to-chop/the-truth"
          exact
          element={<TheTruth />}
        />
        <Route
          path="/ktn/thought-to-chop/tell-your-story"
          exact
          element={<TellYourStory />}
        />
        <Route
          path="/ktn/thought-to-chop/talkatives"
          exact
          element={<Talkatives />}
        />
        <Route
          path="/ktn/thought-to-chop/rob-the-world"
          exact
          element={<RobtheWorld />}
        />
        <Route
          path="/ktn/thought-to-chop/parents-dont-hate-children"
          exact
          element={<ParentsChildren />}
        />
      
        <Route
          path="/ktn/thought-to-chop/meditation-or-insomnia"
          exact
          element={<MeditationInsomnia />}
        />
        <Route
          path="/ktn/thought-to-chop/gym-isnt-neccessary"
          exact
          element={<Gym />}
        />{" "}
        <Route
          path="/ktn/thought-to-chop/how-original-is-your-life"
          exact
          element={<OriginalLife />}
        />
        <Route
          path="/ktn/thought-to-chop/grow-your-confidence"
          exact
          element={<GrowYourConfidence />}
        />
        <Route
          path="/ktn/marketing-and-sales/practical-strategies-journey"
          exact
          element={<PracticalStrategiesJourney />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/mastering-cold-pitching"
          exact
          element={<MasteringColdPitching />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/building-stable-income"
          exact
          element={<BuildingStableIncome />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/persuasive-startegies"
          exact
          element={<PersuasiveStrategies />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/crafting-cold-emails"
          exact
          element={<CraftingColdEmails />}
        />
        <Route
          path="/ktn/marketing-and-sales/succeed-in-any-business"
          exact
          element={<SucceedBusiness />}
        />
        <Route
          path="/ktn/marketing-and-sales/lumpsum-project"
          exact
          element={<LumpSumProjects />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/marketing-law"
          exact
          element={<MarketingLaw />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/hate-the-feeling-of-selling"
          exact
          element={<FeelingOfSelling />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/land-and-retain-clients"
          exact
          element={<RetainClients />}
        />
        <Route
          path="/ktn/marketing-and-sales/whore"
          exact
          element={<Whore />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/cold-outreach-strategy"
          exact
          element={<ColdOutreachStrategy />}
        />
        <Route
          path="/ktn/marketing-and-sales/increase-your-percieved-value"
          exact
          element={<IncreasePercievedValue />}
        />
        <Route
          path="/ktn/afroprenuership/effectively-work-ghostwriter"
          exact
          element={<EffectivelyGhostwriter />}
        />
        <Route
          path="/ktn/afroprenuership/ghostwrite-seo-content"
          exact
          element={<GhostwriteSeoContent />}
        />
        <Route
          path="/ktn/afroprenuership/overcome-writers-block"
          exact
          element={<OvercomeWritersBlock />}
        />
        <Route
          path="/ktn/afroprenuership/establish-yourself-as-a-thought-leader"
          exact
          element={<EstablishYourself />}
        />
          <Route
          path="/ktn/afroprenuership/building_high_performing_ghostwriting_team"
          exact
          element={<HighPerformanceTeam />}
        />
        <Route
          path="/ktn/afroprenuership/ghostwriting-for-authors"
          exact
          element={<GhostwritingForAuthors />}
        /> <Route
        path="/ktn/afroprenuership/ghostwriting-interview"
        exact
        element={<GhostwritingInterviews />}
      />


<Route
        path="/ktn/afroprenuership/build-your-personal-brand"
        exact
        element={<PersonalBrand />}
      />
      
        <Route
          path="/ktn/afroprenuership/attract-highvalue-clients"
          exact
          element={<HighValueClients />}
        />
        <Route
          path="/ktn/afroprenuership/debunking-myths"
          exact
          element={<DebunkingMyths />}
        />
        <Route
          path="/ktn/afroprenuership/craft-compelling-content"
          exact
          element={<CraftCompellingContent />}
        />
        <Route
          path="/ktn/afroprenuership/seven-top-strategies"
          exact
          element={<SevenStrategies />}
        />
        <Route
          path="/ktn/afroprenuership/understanding-your-rights"
          exact
          element={<UnderstandingYourRights />}
        />
        <Route
          path="/ktn/afroprenuership/shape-public-content"
          exact
          element={<ShapePublicContent />}
        />
        <Route
          path="/ktn/afroprenuership/perfect-ghostwriter-project"
          exact
          element={<PerfectGhostwriterProject />}
        />
        <Route
          path="/ktn/afroprenuership/build-sustainable-business"
          exact
          element={<ValueSells />}
        />
        <Route
          path="/ktn/afroprenuership/powerful-playful-persistence"
          exact
          element={<PowerfulPlayfulPersistence />}
        />
        <Route
          path="/ktn/afroprenuership/grow-your-business"
          exact
          element={<GrowYourBusiness />}
        />
        <Route
          path="/ktn/afroprenuership/master-premium-pricing"
          exact
          element={<MasterPricing />}
        />
        <Route
          path="/ktn/afroprenuership/mastering-the-art-of-ghostwriting"
          exact
          element={<MasteringArtGhostwriting />}
        />
        <Route
          path="/ktn/afroprenuership/embracing-uncertainty"
          exact
          element={<EmbracingUncertainty />}
        />
        <Route
          path="/ktn/afroprenuership/what-is-your-cash-cow"
          exact
          element={<CashCow />}
        />
        <Route
          path="/ktn/afroprenuership/trial-and-error"
          exact
          element={<TrialAndError />}
        />
        <Route
          path="/ktn/afroprenuership/cold-outreach"
          exact
          element={<ColdOutreachAfro />}
        />
        <Route
          path="/ktn/afroprenuership/overcome-imposter-syndrome"
          exact
          element={<OvercomeImposterSyndromeAfro />}
        />
        <Route
          path="/ktn/afroprenuership/talent-leverage"
          exact
          element={<TalentLeverage />}
        />
        <Route
          path="/ktn/afroprenuership/bill-clients"
          exact
          element={<BillClientsAfro />}
        />{" "}
        <Route
          path="/ktn/afroprenuership/dont-niche-away-your-profit"
          exact
          element={<DontNiche />}
        />{" "}

<Route
          path="/ktn/afroprenuership/five-signs-ghostwriters-need"
          exact
          element={<FiveSigns />}
        />{" "}

<Route
          path="/ktn/afroprenuership/top-skill-every-ghostwriter-should-have"
          exact
          element={<TopSkill />}
        />{" "}

<Route
          path="/ktn/afroprenuership/writing-a-book-is-great-for-your-business"
          exact
          element={<WritingABook />}
        />{" "}

<Route
          path="/ktn/afroprenuership/key-lessons-from-famous-ghostwritten-books"
          exact
          element={<KeyLessons />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-to-help-clients-choose-the-best-publishing-path"
          exact
          element={<HelpClientChoose />}
        />{" "}


        
<Route
          path="/ktn/afroprenuership/tell-your-story-the-power-of-ghostwriting"
          exact
          element={<TellYourStoryAfro />}
        />{" "}

<Route
          path="/ktn/afroprenuership/expert-tips-to-publicize-your-book"
          exact
          element={<EffectiveTips />}
        />{" "}

        
<Route
          path="/ktn/afroprenuership/why-reading-is-important-for-ghostwriters"
          exact
          element={<ReadingGhostwriters />}
        />{" "}


        
<Route
          path="/ktn/afroprenuership/how-to-create-long-term-client-relationships-as-a-ghostwriter"
          exact
          element={<CreateLongTermClient />}
        />{" "}


<Route
          path="/ktn/afroprenuership/turning-client-feedback-into-success"
          exact
          element={<TurningClient />}
        />{" "}



<Route
          path="/ktn/afroprenuership/how-ghostwriters-can-overcome-anxiety-when-sending-their-first-pitch"
          exact
          element={<OvercomeAnxiety />}
        />{" "}

        
<Route
          path="/ktn/afroprenuership/seven-proven-strategies-to-earn-more-money-as-a-ghostwriter"
          exact
          element={<EarnMoreMoney />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-to-create-a-tempting-ghostwriting-offer"
          exact
          element={<GhostwritingOffer />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-a-ghostwriter-can-help-you-establish-thought-leadership-through-a-book"
          exact
          element={<HelpGhostwriters />}
        />{" "}

<Route
          path="/ktn/afroprenuership/why-top-executives-choose-ghostwriters"
          exact
          element={<TopExecutive />}
        />{" "}

<Route
          path="/ktn/afroprenuership/the-secret-to-a-bestselling-book"
          exact
          element={<BestSelling />}
        />{" "}

<Route
          path="/ktn/afroprenuership/ten-signs-youre-prepared-to-hire-a-ghostwriter"
          exact
          element={<TenSigns />}
        />{" "}

<Route
          path="/ktn/afroprenuership/from-idea-to-publishing-what-to-expect-when-you-work-with a-ghostwriter"
          exact
          element={<IdeaPublishing />}
        />{" "}

<Route
          path="/ktn/afroprenuership/three-success-stories-of-entrepreneurs-who-partnered-with-ghostwriters"
          exact
          element={<SuccessPartnered />}
        />{" "}
<Route
          path="/ktn/afroprenuership/the-power-of-short-form-video-content-in-building-your-personal-brand"
          exact
          element={<ShortForm />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-empathy-can-help-build-you-a-stronger-brand"
          exact
          element={<StrongerBrand />}
        />{" "}

<Route
          path="/ktn/afroprenuership/social-media-for-executives"
          exact
          element={<MediaExecutive />}
        />{" "}

<Route
          path="/ktn/afroprenuership/human-centered-marketing"
          exact
          element={<HumanCenteredMarketing />}
        />{" "}
        
        <Route
          path="/ktn/afroprenuership/the-future-of-content-marketing-in-leadership-branding"
          exact
          element={<LeadershipBranding />}
        />{" "}

<Route
          path="/ktn/afroprenuership/seo-for-thought-leaders"
          exact
          element={<SeoThought />}
        />{" "}

<Route
          path="/ktn/afroprenuership/the-role-of-ghostwriting-in-scaling-thought-leadership"
          exact
          element={<GhostwriterScaling />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-executives-can-enhance-investor-relations-through-effective-communication"
          exact
          element={<ExecutiveEnchance />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-smart-executives-expand-their-influence-without-burnout"
          exact
          element={<SmartExecutive />}
        />{" "}

<Route
          path="/ktn/afroprenuership/creating-new-business-opportunities-through-personal-branding"
          exact
          element={<BusinessOpportunities />}
        />{" "}

<Route
          path="/ktn/afroprenuership/the-power-of-data-driven-communication-in-executive-decision-making"
          exact
          element={<DataDriven />}
        />{" "}

        
<Route
          path="/ktn/afroprenuership/how-to-build-a-profitable-personal-brand-from-nothing"
          exact
          element={<ProfitablePersonal />}
        />{" "}


        
<Route
          path="/ktn/afroprenuership/how-the-best-leaders-own-their-digital-space-using-effective-communication"
          exact
          element={<BestLeaders />}
        />{" "}


<Route
          path="/engage-with-us"
          exact
          element={<InteractHome />} />



        <Route path="/Faq" exact element={<FAQ />} />
     

<Route
          path="/pricing"
          exact
          element={<PricingPage />}
        />
        
        </Routes>
        <Footer />
        <NewsletterPopup />
        <CookieBanner />
      </div>
    );
  };

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
