import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

function CookieBanner() {
  // Check if the user already accepted or declined cookies
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = Cookies.get("cookiesAccepted");
    const cookiesDeclined = Cookies.get("cookiesDeclined");
    if (!cookiesAccepted && !cookiesDeclined) {
      setShowBanner(true); // Show the banner only if no choice has been made
    }
  }, []);

  // Handle "Accept" button
  const handleAccept = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 365 }); // Set cookie for 1 year
    setShowBanner(false); // Hide the banner
  };

  // Handle "Decline" button
  const handleDecline = () => {
    Cookies.set("cookiesDeclined", "true", { expires: 365 }); // Set a decline cookie for 1 year
    setShowBanner(false); // Hide the banner
  };

  if (!showBanner) {
    return null; // Don't render the banner if it's already accepted/declined
  }

  return (
    <div className="fixed left-0 bottom-0 w-full sm:w-[400px] bg-[#14143a] text-white py-6 px-8 rounded-lg shadow-xl z-50 font-playfair">
      <div className="flex flex-col space-y-4">
        <span className="text-sm sm:text-base">
          By clicking “Accept”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
        </span>
      </div>
      <div className="mt-4 flex space-x-4 justify-end">
        <button
          onClick={handleAccept}
          className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-md transition duration-300 font-semibold text-sm"
        >
          Accept
        </button>
        <button
          onClick={handleDecline}
          className="bg-transparent border-2 border-red-600 hover:bg-red-600 hover:text-white text-red-600 px-6 py-2 rounded-md transition duration-300 font-semibold text-sm"
        >
          Decline
        </button>
      </div>
    </div>
  );
}

export default CookieBanner;
