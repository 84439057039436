import { useEffect } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
// import { FaInstagram, FaLinkedinIn, FaSpotify, FaPodcast, FaYoutube } from "react-icons/fa";

export default function IntereactNewsletter() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;

    const apiKey = "mc5E05u5e4O7IlLiRJhm5w"; // Ensure this is correct
    const formId = "7263348"; // Ensure this is correct

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${formId}/subscribe`,
        {
          api_key: apiKey,
          email,
          first_name: name, // ConvertKit expects "first_name"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response.data);
      if (response.data.subscription) {
        alert("Please check your email to confirm your subscription");
      } else {
        alert("Subscription failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error);
      alert("Error occurred. Please try again later.");
    }
  };

  return (
    <div
      className="flex flex-col items-center text-center px-6 py-16 md:py-24 lg:py-32 w-full"
      style={{
        background: "linear-gradient(135deg, #0c145b, #737373, #002772)",
      }}
      data-aos="fade-up"
    >
      <h2 className="text-2xl md:text-5xl font-bold text-white" data-aos="fade-down">
        Subscribe to our Newsletter
      </h2>
      <p className="text-gray-200 mt-4 text-sm lg:text-lg" data-aos="fade-up">
        We’ll send information to elevate your brand’s communication and marketing, and you do not have to pay anything.
      </p>

      {/* Form Section */}
      <form onSubmit={handleSubmit} className="flex flex-col items-center gap-3 w-full max-w-xl mt-6 text-sm lg:text-lg" data-aos="zoom-in">
        <div className="flex flex-col md:flex-row gap-3 w-full">
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
            className="w-full md:w-1/2 border border-gray-300 rounded-lg px-4 py-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300"
            data-aos="fade-right"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email Address"
            required
            className="w-full md:w-1/2 border border-gray-300 rounded-lg px-4 py-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300"
            data-aos="fade-left"
          />
        </div>
        <button
          type="submit"
          className="bg-[#1d3072] text-white px-6 py-3 rounded-lg font-semibold hover:brightness-110 duration-300 will-change-transform hover:bg-[#949a84] hover:scale-105 transition w-full"
          data-aos="zoom-in-up"
        >
          Let's get started
        </button>
      </form>

      {/* Social Media Links */}
      {/* <div className="flex gap-4 mt-6" data-aos="fade-up">
        <a
          href="https://www.instagram.com/kick.thenarrative/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1d3072] text-white p-3 rounded-full text-2xl hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/company/kickandcosynergy/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1d3072] text-white p-3 rounded-full text-2xl hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://open.spotify.com/show/68Ii6MIniyA9NaOR4w975c"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1d3072] text-white p-3 rounded-full text-2xl hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
        >
          <FaSpotify />
        </a>
        <a
          href="https://podcasts.apple.com/us/podcast/kick-the-narrative/id1796561728"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1d3072] text-white p-3 rounded-full text-2xl hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
        >
          <FaPodcast />
        </a>
        <a
          href="https://www.youtube.com/@kickthenarrative"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1d3072] text-white p-3 rounded-full text-2xl hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
        >
          <FaYoutube />
        </a>
      </div> */}
    </div>
  );
}
